<template>
    <v-container fluid fill-height>
        <v-row justify="center">
            <Loader loader />
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data: () => ({
        hash: null,
        redirect: null,
    }),
    computed: {
        ...mapState({
            behavior: (state) => state.behavior,
        }),
    },
    mounted() {
        this.hash = this.$route.params.hash
        this.redirect = this.$route.query.r || '/'
    },
    methods: {
        ...mapActions('user', ['loginUser']),
        submit() {
            this.loginUser({
                hash: this.hash,
                path: this.redirect,
            })
        },
    },
    timers: {
        submit: {
            time: 0,
            autostart: true,
        },
    },
}
</script>
